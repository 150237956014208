import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../layouts/layout"
import CTA from "../components/CTA"
import Visual from "../components/Visuals"
import ScrollMagic from "scrollmagic"
import { appearAnimation } from "../animations"
import * as Int from "../interactions"
import manifestBG from "../images/manifest-bg.jpg"
import * as Buttons from "../components/Buttons"

class OurCompanyMission extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      text: null,
    }
  }

  componentDidMount() {
    this.setState({
      title: this.props.title,
      text: this.props.text,
    })

    const controller = new ScrollMagic.Controller()

    new ScrollMagic.Scene({
      triggerElement: "#mission",
      offset: 50,
    })
      .on("enter", () => {
        appearAnimation(".mission__content small")
        appearAnimation(".mission__content h2", 0.22)
      })
      .addTo(controller)
  }

  render() {
    const title = this.state.title
    const text = this.state.text

    return (
      <section id="mission" key="mission">
        <div className="grid">
          <div className="row">
            <div className="col lg-8-12 lg-push-left-2-12">
              <div className="mission__content js-scroll-target">
                {title && <small className="blue appear">{title}</small>}
                {text && <h2 className="appear">{text}</h2>}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

class OurCompanyManifest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }
    this.manifestBGRef = React.createRef()
    this.canvasRef = React.createRef()
  }

  formCanvas() {
    Int.formCanvas(
      manifestBG,
      this.manifestBGRef.current,
      this.canvasRef.current
    )
  }

  componentDidMount() {
    this.setState({
      content: this.props.content,
    })

    const controller = new ScrollMagic.Controller()

    Array.from(document.querySelectorAll(".manifest__block")).forEach(el => {
      new ScrollMagic.Scene({
        triggerElement: el,
        offset: -50,
      })
        .on("enter", () => {
          // img
          appearAnimation(el.children[1])
          // h2
          appearAnimation(el.children[0].children[0])
          // p
          appearAnimation(el.children[0].children[1], 0.12)
        })
        .addTo(controller)
    })

    this.formCanvas()
    // ? repaints?
    // Repaint canvas on resize
    if (ResizeObserver) {
      var ro = new ResizeObserver(entries => {
        entries.forEach(el => {
          this.formCanvas()
        })
      })
      ro.observe(document.querySelector("body"))
    }
  }

  render() {
    return (
      <section id="manifest" key="manifest">
        <div className="manifest__bg" ref={this.manifestBGRef}>
          <canvas id="manifest-bg" ref={this.canvasRef} />
        </div>
        <div className="grid">
          <div className="row row--ver-middle row--hover-between">
            {this.props.content.map((el, i) => {
              return (
                <div className="col lg-10-12 lg-push-left-1-12" key={el.title}>
                  <div className="manifest__block">
                    {el.title && el.text && (
                      <div className="manifest__block__text text-block text-block--white">
                        {el.title && <h2 className="appear">{el.title}</h2>}
                        {el.text && <p className="appear">{el.text}</p>}
                      </div>
                    )}
                    {el.element && (
                      <div className="manifest__block__element img-box appear">
                        {el.element && <Visual visual={el.element} />}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export class TemplateOurCompany extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      pageContext: this.props.pageContext,
    }
  }

  componentDidMount() {
    console.log("mount")

    const controller = new ScrollMagic.Controller()

    new ScrollMagic.Scene({
      triggerElement: ".certificates-container",
      offset: 50,
    })
      .on("enter", () => {
        appearAnimation(".certificates-container")
      })
      .addTo(controller)
  }

  render() {
    const Mission = this.state.data.wordpressPage.acf.mission
    const Manifest = this.state.data.wordpressPage.acf.manifest
    const Certificates = this.state.data.wordpressPage.acf.certificates

    return (
      <Layout
        lang={this.state.pageContext.lang}
        tpl={this.state.pageContext.templateGT}
        intro="animated"
        introModifier="intro--inner"
        pageContext={this.state.pageContext}
      >
        {Mission.title && Mission.text && (
          <OurCompanyMission title={Mission.title} text={Mission.text} />
        )}

        {Manifest.length > 0 && <OurCompanyManifest content={Manifest} />}

        {Certificates && Certificates.logos && (
          <section id="certificates">
            <div className="grid">
              <div className="certificates-container">
                <div className="certificates">
                  {Certificates.logos.map(logo => {
                    return (
                      <img
                        src={logo.img.localFile.childImageSharp.original.src}
                        key={logo.img.localFile.childImageSharp.original.src}
                      />
                    )
                  })}
                </div>
                {Certificates.title && <h4>{Certificates.title}</h4>}
                <div className="certificates__btn">
                  {Buttons.primary(
                    "white",
                    Certificates.btn.url,
                    Certificates.btn.title,
                    true,
                    "_blank"
                  )}
                </div>
              </div>
            </div>
          </section>
        )}

        <CTA
          modifier="cta--black"
          pageID={this.state.pageContext.id}
          lang={this.state.pageContext.lang}
        />
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        wordpressPage(wordpress_id: { eq: $id }) {
          acf {
            mission {
              title
              text
            }
            manifest {
              title
              text
              element
            }
            certificates {
              title
              btn {
                title
                url
              }
              logos {
                img {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, pageContext) => {
      return <TemplateOurCompany data={data} context={pageContext} {...props} />
    }}
  />
)
